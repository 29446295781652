import { TextAlignment } from '@aurora/shared-generated/types/graphql-schema-types';
import type { ColorCssVariableOrValue } from '@aurora/shared-types/styles';
import type { CSSProperties } from 'react';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './QuiltSectionHeader.module.pcss';

interface Props {
  /**
   * The section title attributes
   */
  title?: {
    /**
     * Whether the title is visible to screen readers only
     */
    showTitle?: boolean;
    /**
     * The title text
     */
    value: string;
  };
  /**
   * The section description attributes
   */
  description?: {
    /**
     * Whether the description is visible to screen readers only
     */
    showDescription?: boolean;
    /**
     * The description text
     */
    value: string;
  };
  /**
   * The color of the section text
   */
  textColor: ColorCssVariableOrValue;
  /**
   * The horizontal alignment of the section text
   */
  textPosition?: TextAlignment;
  /**
   * Class name(s) to apply to the section header
   */
  className?: string;
}

const textAlignmentToClassnameMap: Record<TextAlignment, string> = {
  [TextAlignment.Center]: 'text-center',
  [TextAlignment.Left]: 'text-left',
  [TextAlignment.Right]: 'text-right'
};

/**
 * Renders the text elements found inside a section
 *
 * @author Jonathan Bridges
 */
const QuiltSectionHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  textColor,
  textPosition,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const textStyle: CSSProperties = {
    color: textColor
  };

  const { showTitle, value: titleValue } = title || {};
  const { showDescription, value: descriptionValue } = description || {};

  if (!titleValue && !descriptionValue) {
    return null;
  }

  const isTitleSrOnly: boolean = titleValue && !showTitle;
  const isDescriptionSrOnly: boolean = descriptionValue && !showDescription;

  const isHeaderSrOnly: boolean =
    (isTitleSrOnly && isDescriptionSrOnly) ||
    (isTitleSrOnly && !descriptionValue) ||
    (!titleValue && isDescriptionSrOnly);

  return (
    <div
      className={cx(
        textPosition ? textAlignmentToClassnameMap[textPosition] : 'text-center',
        'text-break',
        { 'sr-only': isHeaderSrOnly },
        className
      )}
      style={textStyle}
      data-testid="QuiltSectionHeader"
    >
      {titleValue && (
        <h2
          className={cx(showTitle ? 'lia-section-header' : 'sr-only', 'mb-0 font-weight-bold')}
          style={textStyle}
          data-testid="QuiltSectionHeader.Title"
        >
          {titleValue}
        </h2>
      )}
      {descriptionValue && (
        <span
          className={cx(showDescription ? 'lia-section-description' : 'sr-only')}
          data-testid="QuiltSectionHeader.Description"
        >
          {descriptionValue}
        </span>
      )}
    </div>
  );
};

export default QuiltSectionHeader;
